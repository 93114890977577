@font-face {
    font-family: 'DMSans-Regular';
    src: URL('../fonts/DMSans-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'DMSans-Bold';
    src: URL('../fonts/DMSans-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'DMSans-Medium';
    src: URL('../fonts/DMSans-Medium.ttf') format('truetype');
  }
  
  p, label {
    font-family: 'DMSans-Regular';
    margin-bottom: 0;
  }
  
  .backgroundSI{
    padding: 20px 150px;
    z-index: 50;
    background: linear-gradient(#F8FAFD, #F8FAFD);/*Color de Fondo*/
  }
  
  .centerDiv {
    text-align: center;
  }
  
  .cancel-logo-style {
    margin-top: 30px;
    width: 200px;
  }
  
  .titleCancel{
    color:#464646;
    text-align: left;
    font-size: 21px;
    font-family: 'DMSans-Medium';
  }
  
  .cancelFormDiv{
    margin-top: 25px;
    padding: 10px 35px;
    border: 1px solid #ECF0F7;/*Color de Formulario*/
    background: #FFFFFF 0% 0% no-repeat padding-box;/*Color de Formulario*/
    border-radius: 14px;
  }
  
  .titlesDiv, .subtitlesDiv{
    margin-bottom: 20px;
  }
  
  .cancelFormTitle {
    color: #2478FF;
    /*font: normal normal normal 20px/50px 'DMSans-Medium';*/
    font-size: 20px;
    font-family: 'DMSans-Medium';
    margin-top: 10px;
  }
  .cancelFormSubTitle {
    color: #2F2F2F;
    /*font: normal normal normal 18px/27px 'DMSans-Regular'*/
    font-size: 18px;
  }
  
  .cancelFormText {
    color: #2F2F2F;
    /*font: normal normal normal 18px/50px 'DMSans-Medium';*/
    font-size: 18px;
    font-family: 'DMSans-Medium';
  }
  
  .cancelFormSubText {
    color: #2F2F2F;
    /*font: normal normal normal 18px/25px 'DMSans-Regular';*/
    font-size: 18px;
  }
  
  .myFormStyle{
    border-radius: 4px;
    padding: 10px;
  }
  
  .spaceForFormRow{
    margin-left: 0px;
    margin-right: 0px;
    justify-content: space-between;
  }
  
  .myFormGroups{
    margin: 0 10px 1rem 0;
    width: calc(48% - 10px);
    align-self: flex-start;
  }
  
  .myRadioStyle{
    color: #6E6E6E;
    /*font: normal normal normal 18px/50px 'DMSans-Regular';*/
    font-size: 18px;
    font-family: 'DMSans-Regular';
  }
  
  .formBasicCheckbox{
    align-items: center;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
  }
  
  .validate-button{
    height: 20px;
    width: 20px;
    margin-right: 10px;
    padding:0;
    background-color: transparent;
    border: 1px solid #AECCFD;
    color: transparent;
    border-radius: 100%;
  }
  
  .validate-button.check:hover{
    background-color: #D5E5FF;
    border-color: #D5E5FF;
    justify-content: center;
    text-align: center;
    display: flex;
  }
  .validate-button.check.selected, .validate-button.check:active{
    background-color: #2478FF;
    border-color: #2478FF;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
  }
  
  .myTextAreaForm{
    min-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .myTextAreaTitle{
    color: #2F2F2F;
    /*font: normal normal normal 18px/50px 'DMSans-Regular';*/
    font-size: 18px;
    max-width: 50%;
    margin-bottom: 10px;
  }
  
  .myTextarea{
    min-width: 100%;
    min-height: 150px;
    max-height: 150px;
    border: 1px solid #D5E5FF;
  }
  
  .sendCancelBtn{
    background-color: #669AFB;
    border: 1px solid #669AFB;
    border-radius: 4px;
    width: 235px;
    max-width: 235px;;
    height: 32px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cancelFormStatus2{
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .finalCheck{
    color: #FFFFFF;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    width: 50px;
    height: 50px;
  }
  
  .myCheckIcon {
    height: 90%;
    width: 80%;
  }
  
  .finalTitleText {
    /*font: normal normal medium 30px/75px 'DMSans-Regular';*/
    font-size: 30px;
  }
  
  .finalSubTitleText{
    /*font: normal normal normal 25px/75px 'DMSans-Regular';*/
    font-size: 25px;
    max-width: 70%;
  }
  
  .notification-container--top-right{
    right: 160px;
  }

  @media (max-width: 1149px) {
    .myRadioStyle{
      font-size: 17px;
    }
  }

  @media (max-width: 1135px) {
    .finalSubTitleText{
      width: 85%;
    }
  }

  @media (max-width: 1114px) {
    .myRadioStyle{
      font-size: 16px;
    }
  }
  
  @media (max-width: 1099px){
    .myFormGroups{
      width: calc(100% - 10px);
    }
    .myRadioStyle{
      font-size: 18px;
    }
  }
  
  @media (max-width: 992px) {
    .backgroundSI{
        padding: 20px 50px;
    }
    .notification-container--top-right{
      right: 60px;
    }
  }

  @media (max-width:830px) {
    .finalTitleText{
      font-size: 25px;
    }
    .finalSubTitleText{
      font-size: 20px;
    }
    .finalCheck{
      width: 40px;
      height: 40px;;
    }
  }
  
  @media (max-width: 767px) {
    .backgroundSI{
        padding: 20px;
    }
    .container{
      max-width: 700px;
    }
  }
  
  @media (max-width: 577px) {
    .cancel-logo-style{
        width: 150px;
    }
    .myTextAreaTitle{
      max-width: 100%;
      font-size: 17px;
    }
  }

  @media (max-width: 511px){
    .myRadioStyle, .myTextAreaTitle{
      font-size: 16px;
    }
    .validate-button{
      margin-right: 5px;
    }
    .cancelFormSubText{
      font-size: 17px;
    }
    .titleCancel{
      font-size: 19px;
    }
  }

  @media (max-width: 495px) {
    .cancelFormTitle{
      font-size: 18px;
    }
    .cancelFormSubTitle, .cancelFormSubText{
      font-size: 16px;
    }
  }
  
  @media (max-width: 487px){
    .cancelFormDiv{
      padding: 10px 15px;
    }
    .myFormStyle{
      padding:10px 0px;
    }
    .notification-container--top-right{
      right: 25px;
    }
  }

  @media (max-width: 453px){
    .titleCancel{
      font-size: 18px;
    }
    .cancelFormText, .cancelFormTitle{
      font-size: 16px;
    }
    .cancelFormSubTitle, .cancelFormSubText{
      font-size: 15px;
    }
  }

  @media (max-width: 433px) {
    .titleCancel{
      font-size: 16px;
    }
  }

  @media (max-width: 412px){
    .myRadioStyle, .myTextAreaTitle{
      font-size: 15px;
    }
    .cancelFormText, .cancelFormTitle{
      font-size: 15px;
    }
    .cancelFormSubTitle, .cancelFormSubText{
      font-size: 14px;
    }
  }
  
  @media (max-width: 400px) {
    .cancel-logo-style{
      width: 40%;
      margin-top: 20px;
    }
    .myCol{
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  @media (max-width: 364px){
    .titleCancel{
      font-size: 15px;
    }
    .cancelFormText, .cancelFormTitle, .myRadioStyle, .myTextAreaTitle{
      font-size: 14px;
    }
    .cancelFormSubTitle, .cancelFormSubText{
      font-size: 13px;
    }
  }
  @media (max-width: 364px){
    .myRadioStyle, .myTextAreaTitle{
      font-size: 13px;
    }
    .titleCancel{
      font-size: 14px;
    }
    .cancelFormText, .cancelFormTitle, .myRadioStyle, .myTextAreaTitle{
      font-size: 13px;
    }
    .cancelFormSubTitle, .cancelFormSubText{
      font-size: 12px;
    }
  }